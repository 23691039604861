
import moment from "moment";

import Permissions from "~/graphql/Authentication/Permissions.gql";
import PropertyOpex from "~/graphql/Portfolio/PropertyOpex.gql";
import PropertyCapex from "~/graphql/Portfolio/PropertyCapex.gql";
import PropertyOverview from "~/graphql/Portfolio/PropertyOverview.gql";
import PropertyIdleTenancy from "~/graphql/Portfolio/PropertyIdleTenancy.gql";
import PropertyTenancyLease from "~/graphql/Portfolio/PropertyTenancyLease.gql";
import UpdateAssetManagementBusinessPlan from "~/graphql/Portfolio/Mutations/UpdateBusinessPlan.gql";
import CreateAssetManagementBusinessPlan from "~/graphql/Portfolio/Mutations/CreateBusinessPlan.gql";
import PropertyPlannedRent from "~/graphql/Portfolio/PropertyPlannedRent.gql";

import GraphMenu from "../../../components/GraphMenu/GraphMenu.vue";
import EditKeyValues from "~/pages/portfolio/components/Edit/EditKeyValues.vue";
import BalanceKeyFigures from "../../../components/Expenses/Balance/BalanceKeyFigures/index.vue";

import { Operator } from "~/helpers/apollo/apollo-utility-helpers";
import { PERMISSIONS } from "~/composables/usePermissions";

import {
  getPropertyArea,
  getSqmRentForProperty,
  getTotalRentForProperty,
  getBusinessPlanForProperty,
  getTenancyTypeDistribution,
  getFinancialRecordsForProperty,
  getNumberOfTenanciesForProperty,
  getTenancyTypeDistributionPrSquareMeter,
  getRentCurrencyForProperty,
} from "~/helpers/portfolio/portfolio-property-helpers";
import {
  formatRentRate,
  formatEvictionRate,
  formatIdleTenancyRateRent,
  formatRentAndIdleTenancyRateRentCombined,
} from "~/helpers/portfolio/portfolio-graph-helpers.js";
import { InputBuilder, GraphInputBuilder } from "~/helpers/apollo/apollo-portfolio-helpers.js";
import { TenancyDecomissionedFilter } from "~/helpers/apollo/apollo-portfolio-filter-helpers";
import { getTotalActualsForFinancialRecords, getMonthlyActualsForFinancialRecords } from "~/helpers/portfolio/portfolio-financial-records-helpers";

import { filter } from "~/composables/useFilter";

const preferenceNamespacePortfolioPropertyOverview = "preferenceNamespacePortfolioPropertyOverview";

export default {
  components: {
    GraphMenu,
    EditKeyValues,
    BalanceKeyFigures,
  },

  inheritAttrs: false,

  props: {
    propertyId: {
      type: String,
      required: true,
    },
  },

  data: function () {
    return {
      idleTenancyRateMenuOpen: false,
      idleTenancyRateFilterDateTo: undefined,
      idleTenancyRateFilterDateFrom: undefined,
      idleTenancyRateFilterPeriodType: undefined,

      evictionRateMenuOpen: false,
      evictionRateFilterDateTo: undefined,
      evictionRateFilterDateFrom: undefined,
      evictionRateFilterPeriodType: undefined,

      rentRateMenuOpen: false,
      rentRateFilterDateFrom: undefined,
      rentRateFilterDateTo: undefined,
      rentRateFilterPeriodType: undefined,

      editBusinessPlanActive: false,
      editableBusinessPlans: [],
    };
  },

  apollo: {
    property: {
      query: PropertyOverview,
      variables() {
        return new InputBuilder()
          .setId(this.propertyId)
          .setFilter(new TenancyDecomissionedFilter())
          .setInput("periodType", "MONTHLY")
          .setInput("rentCategory", "RENT")
          .build();
      },
      update(data) {
        return data.assetManagementProperty;
      },
    },

    capexes: {
      query: PropertyCapex,
      variables() {
        return new InputBuilder()
          .setId(this.propertyId)
          .setFilter({ matches: [{ category_type: [Operator.In, ["capex", "CAPEX"]] }] })
          .setSort("updated_at", "DESC")
          .setPagination(1, 4)
          .build();
      },
      update(data) {
        return data.assetManagementProperty.assetManagementFinancialRecords.items;
      },
    },

    opexes: {
      query: PropertyOpex,
      variables() {
        return new InputBuilder()
          .setId(this.propertyId)
          .setFilter({
            matches: [
              { entry_date: ["gte", moment().subtract(1, "year").format("YYYY-MM-DD")] },
              { entry_date: ["lte", moment().format("YYYY-MM-DD")] },
              { recursive_category_type: [Operator.In, ["opex", "OPEX"]] },
            ],
          })
          .build();
      },
      update(data) {
        return getFinancialRecordsForProperty(data.assetManagementProperty);
      },
    },

    idleTenancyPercent: {
      query: PropertyIdleTenancy,
      variables() {
        return new GraphInputBuilder()
          .setPropertyId(this.propertyId)
          .setDateFrom(this.idleTenancyRateFilterDateFrom)
          .setDateTo(this.idleTenancyRateFilterDateTo)
          .setPeriodType(this.idleTenancyRateFilterPeriodType)
          .build();
      },
      update(data) {
        return data.idleTenancyMetricsForProperty;
      },
      skip() {
        return !this.idleTenancyRateFilterDateFrom || !this.idleTenancyRateFilterDateTo || !this.idleTenancyRateFilterPeriodType;
      },
    },

    idleTenancyAmount: {
      query: PropertyIdleTenancy,
      variables() {
        return new GraphInputBuilder()
          .setPropertyId(this.propertyId)
          .setDateFrom(this.rentRateFilterDateFrom)
          .setDateTo(this.rentRateFilterDateTo)
          .setPeriodType(this.rentRateFilterPeriodType)
          .build();
      },
      update(data) {
        return data.idleTenancyMetricsForProperty;
      },
      skip() {
        return !this.rentRateFilterDateFrom || !this.rentRateFilterDateTo || !this.rentRateFilterPeriodType;
      },
    },

    eviction: {
      query: PropertyTenancyLease,
      variables() {
        return GraphInputBuilder()
          .setPropertyId(this.propertyId)
          .setDateFrom(this.evictionRateFilterDateFrom)
          .setDateTo(this.evictionRateFilterDateTo)
          .setPeriodType(this.evictionRateFilterPeriodType)
          .build();
      },
      update(data) {
        return data.tenancyLeaseOverviewForProperty;
      },
      skip: function () {
        return !this.evictionRateFilterDateFrom || !this.evictionRateFilterDateTo || !this.evictionRateFilterPeriodType;
      },
    },

    rent: {
      query: PropertyPlannedRent,
      variables() {
        return GraphInputBuilder()
          .setPropertyId(this.propertyId)
          .setDateFrom(this.rentRateFilterDateFrom)
          .setDateTo(this.rentRateFilterDateTo)
          .setPeriodType(this.rentRateFilterPeriodType)
          .setCategory("RENT")
          .build();
      },
      update(data) {
        return data.plannedRentsMetricsForProperty;
      },
      skip: function () {
        return !this.rentRateFilterDateFrom || !this.rentRateFilterDateTo || !this.rentRateFilterPeriodType;
      },
    },

    me: {
      query: Permissions,
    },
  },

  computed: {
    filter() {
      return filter;
    },

    meLoading() {
      return this.$apollo.queries.me.loading;
    },

    loading() {
      return this.$apollo.queries.property.loading;
    },

    opexLoading() {
      return this.$apollo.queries.opexes.loading;
    },

    idleTenancyPercentLoading() {
      return this.$apollo.queries.idleTenancyPercent.loading;
    },

    idleTenancyAmountLoading() {
      return this.$apollo.queries.idleTenancyAmount.loading;
    },

    evictionLoading() {
      return this.$apollo.queries.eviction.loading;
    },

    rentLoading() {
      return this.$apollo.queries.rent.loading;
    },

    numberOfUnits() {
      if (this.property == null) {
        return 0;
      }

      return getNumberOfTenanciesForProperty(this.property);
    },

    annualRent() {
      if (this.property == null) {
        return 0;
      }

      return getTotalRentForProperty(this.property);
    },

    area() {
      if (this.property == null) {
        return 0;
      }

      return this.property ? getPropertyArea(this.property) : { area: 0, areaUnit: "–" };
    },

    sqmRent() {
      if (this.property == null) {
        return 0;
      }

      const { rent } = getSqmRentForProperty(this.property);

      return rent;
    },

    allocatedPurchasePrice() {
      if (this.property == null) {
        return 0;
      }

      return getBusinessPlanForProperty(this.property)?.allocatedPurchasePrice ?? 0;
    },

    allocatedPurchasePriceWithCost() {
      if (this.property == null) {
        return 0;
      }

      return getBusinessPlanForProperty(this.property)?.allocatedPurchasePriceWithCost ?? 0;
    },

    businessPlanPrice() {
      if (this.property == null) {
        return 0;
      }

      return getBusinessPlanForProperty(this.property)?.businessPlanPrice ?? 0;
    },

    businessPlanPricePrSqm() {
      if (this.property == null || this.area.area == 0) {
        return 0;
      }

      return (getBusinessPlanForProperty(this.property)?.businessPlanPrice ?? 0) / this.area.area;
    },

    opex() {
      const out = {
        graphData: {
          xaxis: {
            categories: [],
          },

          series: [
            {
              name: "OpEx",
              data: [],
            },
          ],
        },
        rollingAverage: 0,
        currency: undefined,
      };

      if (this.opexes == null) {
        return out;
      }

      const opexLastTwelveMonths = getMonthlyActualsForFinancialRecords(this.opexes, moment().subtract(12, "months"), moment());

      out.graphData = {
        xaxis: {
          categories: opexLastTwelveMonths.map((opex) => moment(opex.date).format("MMM YY")),
        },

        series: [
          {
            name: "OpEx",
            data: opexLastTwelveMonths.map((opex) => opex.actuals),
          },
        ],
      };

      out.rollingAverage = opexLastTwelveMonths.reduce((acc, opex) => acc + opex.actuals, 0) / opexLastTwelveMonths.length;

      out.currency = this.opexes[0]?.currency;

      return out;
    },

    tenancyTypeDistribution() {
      if (this.property == null) {
        return {};
      }

      const dist = getTenancyTypeDistribution(this.property);

      let labels = [];
      let series = [];

      Object.keys(dist).forEach((key) => {
        labels.push(key);
        series.push(dist[key]);
      });

      return {
        labels,
        series,
      };
    },

    tenancyTypeDistributionPrSqm() {
      if (this.property == null) {
        return {};
      }

      const { tenancyTypeDistribution, areaUnit } = getTenancyTypeDistributionPrSquareMeter(this.property);

      let labels = [];
      let series = [];

      Object.keys(tenancyTypeDistribution).forEach((key) => {
        labels.push(key);
        series.push(tenancyTypeDistribution[key]);
      });

      return {
        labels,
        series,
        areaUnit,
      };
    },

    capexTotals() {
      if (this.capexes == null) {
        return 0;
      }

      return getTotalActualsForFinancialRecords(this.capexes);
    },

    latestCapexes() {
      if (this.capexes == null) {
        return [];
      }

      return this.capexes;
    },

    idleTenencyRate() {
      return formatIdleTenancyRateRent(this.idleTenancyPercent, this.idleTenancyRateFilterPeriodType, true);
    },

    evictionRate() {
      return formatEvictionRate(this.eviction, this.evictionRateFilterPeriodType);
    },

    rentRate() {
      return formatRentRate(this.rent, this.rentRateFilterPeriodType);
    },

    rentRateIdleTenancyCombined() {
      if (!this.idleTenancyAmount || !this.rent) return null;

      return formatRentAndIdleTenancyRateRentCombined(this.rent, this.idleTenancyAmount, this.rentRateFilterPeriodType);
    },

    rentRateIdleTenancyCurrency() {
      if (!this.rent) return null;

      return this.rent.find((r) => r.currency)?.currency;
    },

    areaUnitLocal() {
      return this.area.areaUnit ? this.$t(`UNITS_${this.area.areaUnit.toUpperCase()}`) : undefined;
    },

    permissions() {
      return PERMISSIONS;
    },
  },

  watch: {
    idleTenancyRateFilterDateTo(val) {
      this.$userPreferences.setKey(preferenceNamespacePortfolioPropertyOverview, "idleTenancyRateFilterDateTo", val);
    },

    idleTenancyRateFilterDateFrom(val) {
      this.$userPreferences.setKey(preferenceNamespacePortfolioPropertyOverview, "idleTenancyRateFilterDateFrom", val);
    },

    idleTenancyRateFilterPeriodType(val) {
      this.$userPreferences.setKey(preferenceNamespacePortfolioPropertyOverview, "idleTenancyRateFilterPeriodType", val);
    },
  },

  async mounted() {
    // Fetch user preferences
    this.idleTenancyRateFilterDateTo = await this.$userPreferences.getKey(preferenceNamespacePortfolioPropertyOverview, "idleTenancyRateFilterDateTo");
    this.idleTenancyRateFilterDateFrom = await this.$userPreferences.getKey(preferenceNamespacePortfolioPropertyOverview, "idleTenancyRateFilterDateFrom");
    this.idleTenancyRateFilterPeriodType = await this.$userPreferences.getKey(preferenceNamespacePortfolioPropertyOverview, "idleTenancyRateFilterPeriodType");

    this.evictionRateFilterDateTo = await this.$userPreferences.getKey(preferenceNamespacePortfolioPropertyOverview, "evictionRateFilterDateTo");
    this.evictionRateFilterDateFrom = await this.$userPreferences.getKey(preferenceNamespacePortfolioPropertyOverview, "evictionRateFilterDateFrom");
    this.evictionRateFilterPeriodType = await this.$userPreferences.getKey(preferenceNamespacePortfolioPropertyOverview, "evictionRateFilterPeriodType");

    this.rentRateFilterDateTo = await this.$userPreferences.getKey(preferenceNamespacePortfolioPropertyOverview, "rentRateFilterDateTo");
    this.rentRateFilterDateFrom = await this.$userPreferences.getKey(preferenceNamespacePortfolioPropertyOverview, "rentRateFilterDateFrom");
    this.rentRateFilterPeriodType = await this.$userPreferences.getKey(preferenceNamespacePortfolioPropertyOverview, "rentRateFilterPeriodType");
  },

  methods: {
    logAmplitudeEvent(hierarchy, page) {
      const properties = {
        hierarchy: hierarchy,
        page: page,
      };

      this.$amplitude.navigateToTab(properties);
    },

    capexClickMore() {
      this.logAmplitudeEvent("property", "capexes");

      this.$router.push({
        name: "portfolio-property-propertyId-capexes",
        params: {
          propertyId: this.propertyId,
        },
      });
    },

    navigateToUnits() {
      this.logAmplitudeEvent("property", "units");

      this.$router.push({
        name: "portfolio-property-propertyId-units",
        params: {
          propertyId: this.propertyId,
        },
      });
    },

    updateIdleTenancyRateFilter(event) {
      this.idleTenancyRateFilterDateFrom = event.dateFrom;
      this.idleTenancyRateFilterDateTo = event.dateTo;
      this.idleTenancyRateFilterPeriodType = event.periodType;
    },

    toggleIdleTenancyRateMenu() {
      this.idleTenancyRateMenuOpen = !this.idleTenancyRateMenuOpen;
    },

    updateEvictionRateFilter(event) {
      this.evictionRateFilterDateFrom = event.dateFrom;
      this.evictionRateFilterDateTo = event.dateTo;
      this.evictionRateFilterPeriodType = event.periodType;
    },

    toggleEvictionRateMenu() {
      this.evictionRateMenuOpen = !this.evictionRateMenuOpen;
    },

    toggleRentRateMenu() {
      this.rentRateMenuOpen = !this.rentRateMenuOpen;
    },

    rentRateFilter(event) {
      this.rentRateFilterDateFrom = event.dateFrom;
      this.rentRateFilterDateTo = event.dateTo;
      this.rentRateFilterPeriodType = event.periodType;
    },

    opexClickMore() {
      this.logAmplitudeEvent("property", "opexes");

      this.$router.push({
        name: "portfolio-property-propertyId-opexes",
        params: {
          propertyId: this.propertyId,
        },
      });
    },

    onEdit(key, value, label) {
      this.editableBusinessPlans = [
        {
          id: 0,
          key: key,
          value: parseInt(value, 10),
          type: "number",
          label: label,
        },
      ];

      this.editBusinessPlanActive = true;
    },

    async saveBusinessPlan(event) {
      const businessPlanId = getBusinessPlanForProperty(this.property)?.id;
      const input = {
        input: {
          ...event.reduce((acc, curr) => {
            acc[curr.key] = parseInt(curr.value, 10);
            return acc;
          }, {}),
        },
      };

      if (businessPlanId == null) {
        input.input.propertyId = this.propertyId;
        input.input.currency = "DKK";

        await this.$apollo.mutate({
          mutation: CreateAssetManagementBusinessPlan,
          variables: input,
        });

        this.$apollo.queries.property.refetch();
      } else {
        input.input.id = businessPlanId;
        await this.$apollo.mutate({
          mutation: UpdateAssetManagementBusinessPlan,
          variables: input,
        });
      }

      this.editBusinessPlanActive = false;
    },

    hasPermission(permission) {
      return this.me.permissions.includes(permission);
    },

    filterCurrency(value, currency) {
      if (!this.property) return "–";
      return this.filter.currency(value, { currency: currency ?? getRentCurrencyForProperty(this.property) });
    },
  },
};
